import React, { useState, useEffect } from "react";
import { Box, Typography, Select, FormControl, InputLabel, MenuItem, CircularProgress } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
//import API_KEY from './config';


const DomdComp = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '200px' }}>
            <h1>Find Your Matches</h1>
        </div>
    );
};

export default DomdComp;